import m from 'mithril'
import stream from 'mithril/stream'

import isMobile from 'lib/detect-mobile'
import classNames from 'classnames'
import moment from 'moment'
import {
    TextBox,
    Dialog,
    Editor,
} from 'components'
import {
    Group,
    Authority,
    User
} from 'app/models'
import {
    PhotoUploader,
    MultiPhotosUploader
} from 'components/uploader'
import Map from 'app/pages/partials/map'

class GroupPage {
    constructor() {
        this.model = new Group()
        this.languages = [{
            id: 'tw',
            name: '繁體'
        }, {
            id: 'cn',
            name: '簡體'
        }, {
            id: 'en',
            name: '英文'
        }]
        this.select = this.languages[0]
        this.test = false

        this.content = stream("")

        Group.load(1).then((response) => {
            this.model = new Group(response)
            this.content(this.model[this.select.id])
            this.test = true
        })
    }

    save() {
        this.model[this.select.id] = this.content()
        this.model.save().then((response) => {
            swal({
                title: "儲存成功!",
                text:  "已成功編輯資訊!!",
                icon: "success",
                buttons: false,
                timer: 1800,
            })
        })
    }

    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder", [
                        m("h1.main-title.float-left.text-menu", m("i.lnr.lnr-bullhorn.font-weight-bold.mr-3"), "團隊陣容"),
                        m("ol.breadcrumb.float-right",
                            [
                                m("li.breadcrumb-item", "首頁"),
                                m("li.breadcrumb-item.active", "團隊陣容")
                            ]
                        ), m(".clearfix")
                    ])
                )
            ),
            m('.',
                m('.row.py-4', [
                    m('.col-md-12.col-12.d-flex.justify-content-between', [
                        m("",[
                            m("h3.control-label.text-primary.pl-2", `${this.select.name}`),
                        ]), 
                        m("", [
                            
                        this.languages.map((_language) => {
                            if (_language.id != this.select.id) {
                                return m("button.btn.btn-success.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button']", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.test = false
                                        this.model[this.select.id] = this.content()
                                        this.select = _language
                                        this.content(this.model[this.select.id])                                        
                                        m.redraw()
                                        this.test = true
                                    }
                                },
                                    [
                                        m("i.fa.fa-edit"),
                                        _language.name
                                    ]
                                )
                            }
                        }),

                        m("button.btn.btn-primary.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button']", {
                            onclick: (e) => {
                                e.preventDefault()
                                this.save()
                            }
                        },
                            [
                                m("i.fa.fa-edit"),
                                "儲存"
                            ]
                        ), m("button.btn.btn-danger.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button']", {
                            onclick: (e) => {
                                e.preventDefault()
                                this.test = false
                                Group.load(1).then((response) => {
                                    this.model = new Group(response)
                                    this.content(this.model[this.select.id])
                                    // m.redraw()
                                    this.test = true
                                })
                            }
                        },
                            [
                                m("i.fa.fa-edit"),
                                "取消"
                            ]
                        )
                        ]),
                    ]),
                    m('.col-md-12.col-12.pt-4', [
                        (this.test)?
                        m(Editor, {
                            content: this.content
                        }):""
                    ])
                ]),
            )
        ]
    }
}

export default GroupPage
