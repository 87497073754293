import m from 'mithril'
import {
    Dialog,
    TextBox,
} from 'components'
import {
    Mails,
    User
} from 'app/models'


class EditEmail {
    constructor(vnode) {
        this.mails = []
        Mails.fetch().then((response) => {
            this.mails = response
        })
    }
    save() {
        Mails.save(this.mails).then((response) => {
            Dialog.close(true)
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view() {
        return [m(".modal-dialog[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", "寄送E-mail設定"),
                            m("button.close", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m("", [
                            m('form[action=""]',
                                this.mails.map((item, index) => {
                                    return [
                                        m('.row',
                                            [
                                                m('.col-10',
                                                    m(".form-group", [
                                                        m("label.text-dark.mb-1", "人員", index + 1),
                                                        [
                                                            m("span.input-group-addon.ml-1", m('i.fa.fa-envelope-open-o')),
                                                            m(TextBox, {
                                                                oninput: (e) => {
                                                                    item.mail = e.target.value
                                                                },
                                                                class: 'mt-0 form-control',
                                                                value: item.mail,
                                                                type: 'text',
                                                            })
                                                        ]
                                                    ]),
                                                ),
                                                m('.col-2.d-flex.align-items-end.px-1', [
                                                    m('.mb-3', [
                                                        m("button.btn.btn-danger[type='button']", {
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                swal({
                                                                    title: "是否確認刪除",
                                                                    text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: ["取消", "確認"]
                                                                })
                                                                    .then((willDelete) => {
                                                                        if (willDelete) {
                                                                            swal("您的資料已成功刪除", {
                                                                                icon: "success",
                                                                                buttons: {
                                                                                    cancel: false,
                                                                                    confirm: "確認",
                                                                                },
                                                                            });
                                                                                // Mails.delete(item.id).then(() => {
                                                                                //     Mails.fetch().then((response) => {
                                                                                //         this.mails = response
                                                                                //     })
                                                                                // })
                                                                            this.mails.splice(index,1)
                                                                                
                                                                        } else {
                                                                            swal("您的資料未被刪除");
                                                                        }
                                                                    });
                                                            }
                                                        },
                                                            [m("i.fa.fa-trash-o"), "刪除"]
                                                        )
                                                    ])

                                                ])
                                            ]
                                        ),]
                                }),
                                m('.row', [
                                    m('.col-12.my-2', m('button.btn.btn-outline-custom.btn-block', {
                                        onclick: (e) => {
                                            this.mails.push(new Mails)
                                        }
                                    }, '新增'))
                                ])
                            )
                        ])
                    ),
                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                [
                                    m("i.fa.fa-times-circle"),
                                    "取消"
                                ]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.save()
                                    swal({
                                        title: "設定完成!",
                                        text: "已成功設定資訊，請至列表查看!",
                                        icon: "success",
                                        buttons: false,
                                        timer: 1800,
                                    })
                                }
                            },
                                [
                                    m("i.fa.fa-check-circle"),
                                    "儲存"
                                ]
                            )
                        ]
                    )
                ]
            )
        ])]
    }
}

export default EditEmail