import m from 'mithril'

class Script {
    view({
        attrs
    }) {
        return [
            m("script", {
                src: `${window.BASE_URL}/vendor/jquery/jquery.min.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/vendor/bootstrap/js/bootstrap.min.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/vendor/metisMenu/metisMenu.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/vendor/jquery-slimscroll/jquery.slimscroll.min.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/vendor/jquery-sparkline/js/jquery.sparkline.min.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/vendor/bootstrap-progressbar/js/bootstrap-progressbar.min.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/vendor/toastr/toastr.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/lib/scripts/common.js`
            })
        ]
    }
}

export default Script