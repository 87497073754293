import m from 'mithril'
import uuid from 'uuid'
import stream from 'mithril/stream'
import moment from 'moment'
import BaseModel from './_base'

let _claims = [],
    _projects = [],
    _role
let _user = {}

class Authority {
    constructor(args) {
        args = (args) ? args : {};
        this.userId = args.userId || 0
        this.systems = args.systems || []
        this.authorities = args.authorities || []
        this.type = [
            "最高管理權限",
            "一般管理員",
            "dashboard",
            "基地基本資料",
            "施工進度",
            "施工即時攝影",
            "品質管理",
            "日報表管理",
            "文件管理",
            "計價估驗",
            "照片管理",
            "收發文管理",
            "系統帳號管理"]
    }
    

    static set role(value) {
        _role = value
    }

    static get role() {
        return _role
    }

    static set claims(value) {
        _claims = value
    }

    static get claims() {
        return _claims
    }

    static set projects(value) {
        _projects = value
    }

    static get projects() {
        return _projects
    }

    static findProjectAuth(projectId) {
    }

    static get Account() {
        return _user
    }

    static fetch(id) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/users/${id}`
        }).then((response) => {
            _user = JSON.parse(response.permission)            
            this.authorities = JSON.parse(response.permission)
        })
    }

    fetch(id) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/users/${id}`
        }).then((response) => {
            this.authorities = JSON.parse(response.permission)
        })
    }

 
    toggleRoleType(claim) {
        var roles = [
            "最高管理權限",
            "一般管理員",
            "dashboard",
            "基地基本資料",
            "施工進度",
            "施工即時攝影",
            "品質管理",
            "日報表管理",
            "文件管理",
            "計價估驗",
            "照片管理",
            "收發文管理",
            "系統帳號管理"]
        roles.map((role) => {
            const filterIndex = this.authorities.findIndex((item) => {
                return item == role
            })
            if (~filterIndex) {
                this.authorities.splice(filterIndex, 1)
            }
        })
        this.authorities.push(claim)
    }

    static checkUserAuth(claim) {
        let query = claim.toUpperCase();
        const filterItems = _user.filter(item => item.toUpperCase().indexOf(query) >= 0);
        return filterItems.length > 0
    }

    checkAuth(claim) {
        let query = claim.toUpperCase();
        const filterItems = this.authorities.filter(item => item.toUpperCase().indexOf(query) >= 0);
        return filterItems.length > 0
    }

    toggleAuth(claim) {
        let query = claim.toUpperCase();
        const filterIndex = this.authorities.findIndex((item) => {
            return item == query
        })
        if (~filterIndex) {
            return this.authorities.splice(filterIndex, 1)
        }

        this.authorities.push(query)
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/users/authorities`,
            data: this
        })
    }
}

export default Authority