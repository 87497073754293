import m from 'mithril'
import {
    Dialog
} from 'components'
import classNames from 'classnames'
import {
    UserDialog,
    // PasswordDialog,
    // UserNameDialog,
    UserPermissionDialog,
    // DetailDialog
} from './dialogs'
import {
    User
} from 'app/models'


class AccountPage {
    constructor() {
        this.users = []
        User.fetch().then(response => {
            this.users = response
        })
    }
    user_status(status) {
        let result = {
            css: '',
            name: ''
        }
        switch (status) {
            case 0:
                result = {
                    css: 'text-muted',
                    name: '未啟用'
                }
                break;

            case 1:
                result = {
                    css: 'text-danger',
                    name: '停用中'
                }
                break;

            case 2:
                result = {
                    css: 'text-warning',
                    name: '鎖定中'
                }
                break;

            case 3:
                result = {
                    css: 'text-success',
                    name: '啟用中'
                }
                break;
        }
        return result
    }
    view() {
        return m(".row",
            m(".col-xl-12",
                m(".breadcrumb-holder",
                    [
                        m("h1.main-title.float-left.text-menu",
                            m("i.lnr.lnr-cog.font-weight-bold.mr-3"), "系統帳號管理"
                        ),
                        m("ol.breadcrumb.float-right",
                            [
                                m("li.breadcrumb-item",
                                    "首頁"
                                ),
                                m("li.breadcrumb-item.active",
                                    "系統帳號管理"
                                )
                            ]
                        ),
                        m(".clearfix")
                    ]
                )
            ),
            m(".col-md-12",
                m(".panel-content",
                    [
                        m('.card.mb-3',
                            m('.card-header.d-flex.justify-content-between.align-items-center',
                                m("h3", m("i.fa.fa-table.mr-2"), "管理人員"),
                                m("button.btn.btn-primary.btn-sm[type='button']", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            Dialog.show(UserDialog, {
                                                attrs: {
                                                    model: new User(),
                                                    data: this.users,
                                                },
                                                didHide: () => {
                                                    User.fetch().then(response => {
                                                        this.users = response
                                                    })
                                                }
                                            })
                                        }
                                    },
                                    [
                                        m("i.fa.fa-plus-circle.mr-1"),
                                        "新增管理人員"
                                    ]
                                )
                            ),
                        )
                    ]
                )
            ),
            this.users.map((user) => {
                let status = this.user_status(user.status)
                return [m(".zi_widget.col-md-3.py-3",
                    m(".card.btn.cur-p.btn-light",
                        m(".card-body", [
                            m(".card-title.h3", user.name),
                            m(".card-subtitle.mb-2.text-muted", user.email),
                            m(`.span.pb-2.${status.css}`, status.name),
                            m('', [
                                m('a.px-3.tool[href="#"][title="修改個人資料"]', {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        Dialog.show(UserDialog, {
                                            attrs: {
                                                model: new User(user),
                                                data: this.users
                                            },
                                            didHide: () => {
                                                User.fetch().then(response => {
                                                    this.users = response
                                                })
                                            }
                                        })
                                    }
                                }, [
                                    m('i.fa.fa-user-o', {
                                        style: {
                                            fontSize: '1.4rem'
                                        }

                                    })
                                ]),
                                m('a.px-3[href="#"][title="修改權限"]', {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        Dialog.show(UserPermissionDialog, {
                                            attrs: {
                                                id: user.id
                                            }
                                        })
                                    }
                                }, m('i.fa.fa-check-square-o', {
                                    style: {
                                        fontSize: '1.4rem'
                                    }

                                })),     
                                m('a.px-3[href="#"][title="重發認證信"]', {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        User.sendAuthorizeMail(user.id).then((response) => {
                                            alert("已寄出！")
                                        })
                                        
                                    }
                                }, m('i.fa.fa-envelope-open-o', {
                                    style: {
                                        fontSize: '1.4rem'
                                    }

                                })),
                                
                                (user.activated) ? [
                                    m('a.px-3[href="#"][title="停用帳號"]', {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            if (confirm("是否停用該帳號")) {
                                                User.disabled(user.id)
                                                    .then(() => {
                                                        user.activated = 0
                                                        alert('停用成功')
                                                    })
                                            }
                                        }
                                    },
                                        [
                                            m('i.fa.fa-lock',{
                                                style: {
                                                    fontSize: '1.4rem'
                                                }
                                            }),
                                            // m('span.pl-2', "停用帳號")
                                        ]
                                    )
                                ] : [
                                        m(`a.px-3[href="#"][title="啟用帳號"]`, {
                                            onclick: (e) => {
                                                e.preventDefault()
                                                if (confirm("是否啟用該帳號")) {
                                                    User.enabled(user.id)
                                                        .then(() => {
                                                            user.activated = 1
                                                            alert('啟用帳號')
                                                        })
                                                }
                                            }
                                        },
                                            [
                                                m('i.fa.fa-unlock-alt',{
                                                    style: {
                                                        fontSize: '1.4rem'
                                                    }
                                                }),
                                                // m('span.pl-2', "啟用帳號")
                                            ]
                                        )
                                    ],
                                (user.id != window.USER_ID)?[
                                m('a.px-3[href="#"][title="刪除帳號"]', {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            if (confirm("是否刪除該使用者")) {
                                                User.remove(user.id).then((response) => {
                                                    User.fetch().then(response => {
                                                        this.users = response
                                                    })
                                                }).catch((response) => {
                                                    alert(response.Error)
                                                })
                                            }
                                        }
                                    },
                                    [
                                        m('i.fa.fa-trash-o', {
                                            style: {
                                                fontSize: '1.4rem'
                                            }
                                        })
                                    ])
                                ]:""

                            ])
                        ])

                    )
                )]
            }),
        )

    }
}

export default AccountPage