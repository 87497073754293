import m from 'mithril'
import EditComponent from './edit'
import IntroComponent from './intro'
import classNames from 'classnames'
import isMobile from 'lib/detect-mobile'
import moment from 'moment'
import {
    Dialog
} from 'components'
import {
    Work,
    WorkIntro,
    Authority
} from 'app/models'
import MoveDialog from 'components/dialog/moveDialog'


class WorkPage {
    constructor() {
        this.works = []
        this.select = null
        Work.fetch().then((response) => {
            this.works = response
        })
    }


    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder",
                        [
                            m("h1.main-title.float-left.text-menu",
                                m("i.lnr.lnr-book.font-weight-bold.mr-3"), "代表作品"
                            ),
                            m("ol.breadcrumb.float-right",
                                [
                                    m("li.breadcrumb-item",
                                        "首頁"
                                    ),
                                    m("li.breadcrumb-item.active",
                                        "代表作品"
                                    )
                                ]
                            ),
                            m(".clearfix")
                        ]
                    )
                )
            ),
            m(".row",
                m(".col-md-12",
                    m(".panel-content",
                        m(".card.mb-3", {
                            style: {
                                overflow: "auto",
                                height: "calc(100vh - 190px)"
                            }
                        },
                            [
                                m(".card-header", {
                                    style: {
                                        position: "sticky",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        backgroundColor: "#eee"
                                    }
                                },
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-table.mr-2"), "代表作品"]),

                                        ]
                                    ),
                                ),
                                m(".card-body", [
                                    m(".row.w-100.no-gutters", [
                                        m(".col-md-3.text-right", [
                                            m("button.btn.btn-primary.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(MoveDialog, {
                                                        attrs: {
                                                            size: "sm",
                                                            conponent: EditComponent,
                                                            model: new Work(),
                                                        },
                                                        didHide: (returnValue) => {
                                                            if (returnValue) {
                                                                Work.fetch().then((response) => {
                                                                    this.works = response
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                            }, [m("i.fa.fa-plus-circle.mr-1"), "新增類別"]),
                                            m(".row.w-100.align-items-center", [
                                                this.works.map((work) => {
                                                    return [m(".col-8.px-2.py-1", [
                                                        m(".border.btn.btn-outline-secondary.w-100", {
                                                            class: classNames({
                                                                'active': this.select == work,
                                                            }),
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                this.select = work
                                                            }
                                                        }, work.tw),
                                                    ]),
                                                    m(".col-4", [
                                                        m("button.btn.btn-sm.mr-1", {
                                                            style: {
                                                                backgroundColor: "#e2f0d9"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                Dialog.show(MoveDialog, {
                                                                    attrs: {
                                                                        size: "sm",
                                                                        conponent: EditComponent,
                                                                        model: new Work(work),
                                                                    },
                                                                    didHide: (returnValue) => {
                                                                        if (returnValue) {
                                                                            Work.fetch().then((response) => {
                                                                                this.works = response
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        },
                                                            [
                                                                m("i.fa.fa-edit"),
                                                            ]
                                                        ),
                                                        m("button.btn.btn-sm", {
                                                            style: {
                                                                backgroundColor: "#ffbdbe"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                swal({
                                                                    title: "是否確認刪除",
                                                                    text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: ["取消", "確認"]
                                                                })
                                                                    .then((willDelete) => {
                                                                        if (willDelete) {
                                                                            swal("您的資料已成功刪除", {
                                                                                icon: "success",
                                                                                buttons: {
                                                                                    cancel: false,
                                                                                    confirm: "確認",
                                                                                },
                                                                            });
                                                                            Work.delete(work.id).then(() => {
                                                                                Work.fetch()
                                                                            })
                                                                        } else {
                                                                            swal("您的資料未被刪除");
                                                                        }
                                                                    });
                                                            }
                                                        },
                                                            [
                                                                m("i.fa.fa-trash"),
                                                            ]
                                                        )
                                                    ])
                                                    ]
                                                })
                                            ])
                                        ]),
                                        m(".col-2"),
                                        m(".col-md-7", [
                                            (this.select != null) ? [
                                                m(".row.no-gutters.w-100", [
                                                    m(".col-12.text-right", [
                                                        m("button.btn.btn-primary.btn-sm[type='button']", {
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                Dialog.show(MoveDialog, {
                                                                    attrs: {
                                                                        size: "lg",
                                                                        isMove:false,
                                                                        conponent: IntroComponent,
                                                                        model: new WorkIntro({
                                                                            work_id: this.select.id
                                                                        }),
                                                                    },
                                                                    didHide: (returnValue) => {
                                                                        if (returnValue) {
                                                                            Work.fetch().then((response) => {
                                                                                this.works = response
                                                                                this.select = this.works[this.works.findIndex(p => p.id == this.select.id)]
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        }, [m("i.fa.fa-plus-circle.mr-1"), "新增作品"]),
                                                    ]),

                                                    this.select.intros.map((intro) => {
                                                        return [
                                                            m(".col-4.px-2.py-1", [
                                                                m(".row.no-gutters.w-100.align-items-center", [
                                                                    m(".col-10",
                                                                        m(".border.btn.w-100", {
                                                                            onclick: (e) => {
                                                                                e.preventDefault()
                                                                                Dialog.show(MoveDialog, {
                                                                                    attrs: {
                                                                                        size:"lg",
                                                                                        conponent: IntroComponent,
                                                                                        model: new WorkIntro(intro),
                                                                                    },
                                                                                    didHide: (returnValue) => {
                                                                                        if (returnValue) {
                                                                                            Work.fetch().then((response) => {
                                                                                                this.works = response
                                                                                                this.select = this.works[this.works.findIndex(p => p.id == this.select.id)]
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                })

                                                                            }
                                                                        }, intro.tw)
                                                                    ),
                                                                    m(".col-2.pl-2", [
                                                                        m("button.btn.btn-sm", {
                                                                            style: {
                                                                                backgroundColor: "#ffbdbe"
                                                                            },
                                                                            onclick: (e) => {
                                                                                e.preventDefault()
                                                                                swal({
                                                                                    title: "是否確認刪除",
                                                                                    text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                                    icon: "warning",
                                                                                    dangerMode: true,
                                                                                    buttons: ["取消", "確認"]
                                                                                })
                                                                                    .then((willDelete) => {
                                                                                        if (willDelete) {
                                                                                            swal("您的資料已成功刪除", {
                                                                                                icon: "success",
                                                                                                buttons: {
                                                                                                    cancel: false,
                                                                                                    confirm: "確認",
                                                                                                },
                                                                                            });
                                                                                            WorkIntro.delete(intro.id).then(() => {
                                                                                                this.select.intros.splice(this.select.intros.indexOf(intro), 1)
                                                                                            })
                                                                                        } else {
                                                                                            swal("您的資料未被刪除");
                                                                                        }
                                                                                    });
                                                                            }
                                                                        },
                                                                            [
                                                                                m("i.fa.fa-trash"),
                                                                            ]
                                                                        )
                                                                    ])

                                                                ])

                                                            ])
                                                        ]
                                                    })
                                                ])

                                            ] : ""
                                        ])
                                    ])

                                ])
                            ]
                        )
                    )
                )
            ),
        ]
    }
}

export default WorkPage