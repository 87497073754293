import m from 'mithril'
import Uploader from './core'

class DataUploader {
    constructor(vnode) {
        this.uploader = new Uploader()
        this.filename = vnode.attrs.filename        
    }
    view(vnode) {
        return m('figure', [
            m('.g-preivew-container.mx-auto', {
                onclick: (e) => {
                    vnode.dom.querySelector('.g-file_browser').click()
                }
            }, [
                m('.g-preivew-content.d-inline-block.mx-auto.border.rounded.p-1.position-relative', [
                    m('img.img-fluid', {
                        src: this.uploader.imgSrc() ? this.uploader.imgSrc() : vnode.attrs.path
                    }),
                    m('.g-preivew-mask'),
                    m('.d-flex.justify-content-center.align-items-end.position-absolute', {
                        style: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        }
                    }, [
                        (this.uploader.progress()) ? [
                            m('.progress.mb-0', [
                                m('.determinate', {
                                    style: {
                                        width: `${this.uploader.progress()}%`
                                    }
                                })
                            ])
                        ] : ''
                    ])
                ]),
                m('input.g-file_browser[type="file"][name="files[]"][accept="application/pdf,image/*,.csv,.xls,.xlsx,.doc,.docx"]', {
                    onchange: (e) => {
                        if (vnode.attrs.isDisabled && vnode.attrs.isDisabled()) {
                            return false
                        }
                        if (e.target.files.length === 0) {
                            return false
                        }
                        if (!this.uploader.filterImageExtension(e.target.files)) {
                            alert('不支援上傳的檔案格式!')
                            return false
                        }
                        this.uploader.uploadToServer(e.target.files[0],vnode.attrs.url).then((response) => {
                            this.filename(response.filename)
                        })
                    }
                })
            ]),
            (vnode.attrs.caption) ? [
                m('figcaption.figure-caption.py-1', [
                    vnode.attrs.caption
                ])
            ] : ''
        ])
    }
}

export default DataUploader