import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    Editor,
} from 'components'
import {
    User,
    ReportUp,
    Authority
} from 'app/models'
import Uploader from 'components/uploader/core'


class IntroComponent {
    constructor(vnode) {
        this.model = vnode.attrs.model        
    }
    save() {       
        if (this.model.validate()) {
            return false
        }
        this.model.save().then((response) => {
            swal({
                title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
                text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
                icon: "success",
                buttons: false,
                timer: 1800,
            }).then((response)=>{
                Dialog.close(true)
            })
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return [
            m(".modal-body",
                m("",
                    m("form[id='basic-form'][method='post'][novalidate]",
                        m('.row.m-0', [
                            m('.col-md-12',
                                m(".form-group", [
                                    m("label.text-dark.mb-1", "繁體"),
                                    [                                        
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.tw = e.target.value
                                            },
                                            class: 'mt-0 form-control',
                                            value: this.model.tw,
                                            type: 'text',
                                        })
                                    ]
                                ]),
                            ),
                            m('.col-md-12',
                                m(".form-group", [
                                    m("label.text-dark.mb-1", "英文"),
                                    [
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.en = e.target.value
                                            },
                                            class: 'mt-0 form-control',
                                            value: this.model.en,
                                            type: 'text',
                                        })
                                    ]
                                ]),
                            ),
                            m('.col-md-12',
                                m(".form-group", [
                                    m("label.text-dark.mb-1", "簡體"),
                                    [
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.cn = e.target.value
                                            },
                                            class: 'mt-0 form-control',
                                            value: this.model.cn,
                                            type: 'text',
                                        })
                                    ]
                                ]),
                            ),
                            m('.col-md-12',
                                m(".form-group", [
                                    m("label.text-dark.mb-1", "排序"),
                                    [
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.sort = e.target.value
                                            },
                                            class: 'mt-0 form-control',
                                            value: this.model.sort,
                                            type: 'number',
                                            validate: () => {
                                                this.model.valid('sort')
                                            },
                                            error: this.model.error('sort'),
                                        })
                                    ]
                                ]),
                            ),
                            
                        ]),
                    )
                )
            ),

            m(".modal-footer",
                [
                    m("button.btn.btn-label.rounded", {
                        onclick: (e) => {
                            e.preventDefault()
                            this.close()
                        }
                    },
                        [m("i.fa.fa-times-circle"), "關閉"]
                    ),
                        m("button.btn.btn-primary[type='button']", {
                            onclick: (e) => {
                                e.preventDefault()
                                this.save()
                                
                            }
                        },
                            [m("i.fa.fa-check-circle"), "儲存"]
                        )
                ],
               
            )
        ]


    }
}

export default IntroComponent
