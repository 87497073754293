import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {

   }

class Banners extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.filename = args.filename || '';
        this.website = args.website || '#';
        this.sort = args.sort || 0;
        this.created_at = moment(args.created_at).format("YYYY-MM-DD") || "";
        this.updated_at = moment(args.updated_at).format("YYYY-MM-DD") || "";
    }

    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/banner`,
        })
    }

    static load(id) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/banner/${id}`,
        })
    }

    static save(datas) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/banner`,
            data: datas
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/banner/${id}`,
        })
    }
}

export default Banners