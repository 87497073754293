export const ManagementItems = [{
    href: '/',
    title: '首頁',
    icon: 'lnr.lnr-home',
    regex: /\//,
    auth: 'dashboard',
}, {
    href: '/basic',
    title: '關於Aid',
    icon: 'lnr.lnr-list',
    regex: /\/basic/,
    auth: '關於AID'
}, {
    href: '/group',
    title: '團隊陣容',
    icon: 'fa.fa-newspaper-o',
    regex: /\/group/,
    auth: '團隊陣容'
}, {
    href: '/history',
    title: '歷史業績',
    icon: 'lnr.lnr-book',
    regex: /\/history/,
    auth: '歷史業績'
}, {
    href: '/work',
    title: '代表作品',
    icon: 'lnr.lnr-layers',
    regex: /\/work/,
    auth: '代表作品',
}, {
    href: '/news',
    title: '最新消息',
    icon: 'lnr.lnr-calendar-full',
    regex: /\/news/,
    auth: '最新消息'
}, {
    href: '/contact',
    title: '聯絡我們',
    icon: 'lnr.lnr-dice',
    regex: /\/contact/,
    auth: '聯絡我們'
}, {
    href: '/account',
    title: '系統帳號管理',
    icon: 'lnr.lnr-cog',
    regex: /\/account/,
    auth: '系統帳號管理'
}, {
    href: '/control',
    title: '網站設定',
    icon: 'lnr.lnr-laptop-phone',
    regex: /\/control/,
    auth: '網站設定',

}]

