import m from 'mithril'
import classNames from 'classnames'
import uuid from 'uuid'
import Uploader from './core'

const isOverlap = (dragObjectRect, dragOverObjectRect, shiftX, shiftY) => {
    let dragLeft = dragObjectRect.left + shiftX,
        dragTop = dragObjectRect.top + shiftY,
        dragRight = dragObjectRect.right + shiftX,
        dragBottom = dragObjectRect.bottom + shiftY

    const left = Math.max(dragLeft, dragOverObjectRect.left)
    const right = Math.min(dragRight, dragOverObjectRect.right)
    const top = Math.max(dragTop, dragOverObjectRect.top)
    const bottom = Math.min(dragBottom, dragOverObjectRect.bottom)
    if (left < right && top < bottom) {
        const width = right - left
        const height = bottom - top
        const intersec = width * height
        if (intersec > (dragOverObjectRect.width * dragOverObjectRect.height) / 2) {
            return true
        }
    }
    return false
}

let shift, dragObjectKey, dragObjectRect

class Thumb {
    constructor(vnode) {
        this.dragging = false
    }
    findeItem(items, name) {
        return items.findIndex((item) => {
            return item.filename == name
        })
    }
    getPhoto(photo) {
        if (typeof photo === 'function') {
            return photo()
        }
        return photo
    }
    view(vnode) {
        if (!vnode.attrs.photo) {
            return
        }
        return m('.g-thumbnail.border.rounded', {
            draggable: (vnode.attrs.readonly) ? false : true,
            style: {
                backgroundImage: (this.getPhoto(vnode.attrs.photo)) ? `url(${this.getPhoto(vnode.attrs.photo)})` : false,
                cursor: (!vnode.attrs.readonly) ? 'move' : 'default'
            },
            class: classNames({
                'dragging': (!vnode.attrs.readonly) ? this.dragging : false
            }),
            onmousedown: (e) => {
                dragObjectRect = e.currentTarget.getBoundingClientRect()
            },
            onmouseover: (e) => {
                if (vnode.attrs.readonly) {
                    return false
                }
                const trash = vnode.dom.querySelector('.g-thumbnail-trash')
                trash.style.opacity = 1;
            },
            onmouseout: (e) => {
                if (vnode.attrs.readonly) {
                    return false
                }
                const trash = vnode.dom.querySelector('.g-thumbnail-trash')
                trash.style.opacity = 0;
            },
            ondragstart: (e) => {
                if (vnode.attrs.readonly) {
                    return false
                }
                const trash = vnode.dom.querySelector('.g-thumbnail-trash')
                trash.style.opacity = 0;
                e.dataTransfer.effectAllowed = 'move'
                shift = {
                    x: e.pageX,
                    y: e.pageY
                }
                dragObjectKey = vnode.attrs.key
                dragObjectRect = e.currentTarget.getBoundingClientRect()
                this.cloneNode = e.currentTarget.cloneNode(true)
                this.cloneNode.style.width = dragObjectRect.width + 'px'
                this.cloneNode.style.height = dragObjectRect.height + 'px'
                this.cloneNode.style.position = 'fixed'
                this.cloneNode.style.bottom = '-10000px'
                this.cloneNode.style.backgroundSize = 'cover'
                this.cloneNode.style.backgroundPosition = 'center'

                document.body.appendChild(this.cloneNode)
                e.dataTransfer.setDragImage(this.cloneNode, e.clientX - dragObjectRect.left, e.clientY - dragObjectRect.top)
                this.dragging = true
            },
            ondragover: (e) => {
                e.preventDefault()

                if (vnode.attrs.key === dragObjectKey) {
                    return false
                }

                const mouseShift = {
                    x: e.pageX - shift.x,
                    y: e.pageY - shift.y
                }
                const overRect = e.currentTarget.getBoundingClientRect()
                //是否overlap超過drag over object的一半面積
                const overlap = isOverlap(dragObjectRect, overRect, mouseShift.x, mouseShift.y)

                if (overlap) {
                    const to = this.findeItem(vnode.attrs.items(), vnode.attrs.key)
                    const from = this.findeItem(vnode.attrs.items(), dragObjectKey)
                    vnode.attrs.items().splice(to, 0, vnode.attrs.items().splice(from, 1)[0])
                }
            },
            ondragend: (e) => {
                this.dragging = false
                document.body.removeChild(this.cloneNode)

            }
        }, [
                (!vnode.attrs.readonly) ? [
                    m('.g-thumbnail-trash', [
                        m('a', {
                            title: '刪除圖片',
                            href: '#',
                            onclick: (e) => {
                                e.preventDefault()
                                const idx = this.findeItem(vnode.attrs.items(), vnode.attrs.key)
                                vnode.attrs.items().splice(idx, 1)
                            }
                        }, m('i.fa.fa-trash-o.text-white'))
                    ]),
                    (vnode.attrs.progress && vnode.attrs.progress()) ? [
                        m('.d-flex.justify-content-center.align-items-end.position-absolute', {
                            style: {
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0
                            }
                        }, [
                                m('.progress.mb-0', [
                                    m('.determinate', {
                                        style: {
                                            width: `${vnode.attrs.progress()}%`
                                        }
                                    })
                                ])
                            ])
                    ] : ''

                ] : ''
            ])
    }
}

class MultiPhotosUploader {
    constructor(vnode) {
        this.items = vnode.attrs.items
    }
    view(vnode) {
        return m('.g-photos_wrapper.d-flex.flex-wrap.justify-content-center.justify-content-md-start', {
            style: {
                maxHeight: '100%',
                margin: '10px'
            }
        }, [
                this.items().map((item) => {
                    name = item.filename
                    item = vnode.attrs.storepath + item.filename
                    // if (item.storepath) {
                    //     item = item.storepath + item
                    //     console.log("if")
                    // } else if (item.filename) {
                    //     item.photo = vnode.attrs.storepath + item.filename
                    //     console.log("else if")

                    // }
                    return m(Thumb, {
                        items: this.items,
                        key: name,
                        id: item.id,
                        photo: item,
                        progress: item.progress,
                        readonly: vnode.attrs.disabled
                    })
                }),
                (!vnode.attrs.disabled) ? [
                    m('label.g-uploader-indicator.d-flex.align-items.center[for="g-files_browser"]', {
                        // onclick: (e) => {
                        //     vnode.dom.querySelector('.g-file_browser').click()
                        // }
                    }, [
                            m('i.fa.fa-upload', {
                                style: {
                                    lineHeight: "180px"
                                }
                            })
                        ]),
                    m('input.g-file_browser[type="file"][name="files[]"][accept="image/*"][id="g-files_browser"]', {
                        multiple: true,
                        onchange: (e) => {
                            if (e.target.files.length === 0) {
                                return false
                            }
                            for (let i = 0; i < e.target.files.length; i++) {
                                const id = uuid()
                                const uploader = new Uploader
                                if (!uploader.filterImageExtension(e.target.files)) {
                                    alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                                    return false
                                }
                                uploader.uploadImage(e.target.files[i], {
                                    url: vnode.attrs.url,
                                    preview: true,
                                    width: vnode.attrs.width,
                                    height: vnode.attrs.height,
                                    folder: vnode.attrs.folder,
                                    type: "photo",
                                }).then((response) => {
                                    const idx = this.items().findIndex((item) => {
                                        return item.id == id
                                    })
                                    if (~idx) {
                                        this.items()[idx].filename = response.filename
                                    }
                                    // this.items()[this.items().length] = response.filename
                                })
                                this.items().push({
                                    id: id,
                                    filename: null
                                })
                            }
                        }
                    })
                ] : '',
            ])
    }
}

export default MultiPhotosUploader