import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {

   }

class Mails extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.mail = args.mail || '';
        this.created_at = moment(args.created_at).format("YYYY-MM-DD") || "";
        this.updated_at = moment(args.updated_at).format("YYYY-MM-DD") || "";
    }

    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/mail`,
        })
    }

    static load(id) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/mail/${id}`,
        })
    }

    static save(data) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/mail`,
            data: data
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/mail/${id}`,
        })
    }
}

export default Mails