import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {

   }

class Method extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.name = args.name || '';
    }

    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/method`,
        })
    }

    static load(id) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/method/${id}`,
        })
    }

    static save(data) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/method`,
            data: data
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/method/${id}`,
        })
    }
}

export default Method