import m from 'mithril'
import {
    Dialog,
    Textbox,
    TextField,
    TextArea
} from 'components'
import {
    User
} from 'app/models'


class UserDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.data = vnode.attrs.data
    }
    save() {
        this.check = true
        if (!this.model.validate()) {
            if (this.model.id == '') {
                this.data.map((item) => {
                    if (item.email == this.model.email) {
                        alert("E-mail已使用，請重新輸入")
                        this.check = false
                        return false
                    }

                })
                if (this.check) {
                    User.create(this.model.name, this.model.email).then(() => {
                        Dialog.close()
                    })
                }
            }
            else {
                this.model.update().then((response) => {
                    Dialog.close()
                })
            }

        }
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    isNewUser() {
        return (this.model.id == '')
    }
    view(vnode) {
        return m('.modal-dialog', [
            m('.modal-content.shadow-lg', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        (this.model.id != 0) ? `編輯使用者` : "新增使用者"
                    ]),
                    m('button[type="button"].close', {
                        onclick: (e) => {
                            this.close()
                        }
                    }, [
                            m('span', m.trust('&times;'))
                        ])
                ]),
                m(".modal-body.py-3", [
                    m('.container-fluid', [
                        m('.row', [
                            m('.col-10.mx-auto', [
                                m(Textbox, {
                                    label: "使用者名字",
                                    model: this.model,
                                    field: 'name',
                                    input: {
                                        maxlength: 100
                                    }
                                })
                            ]),
                            m('.col-10.mx-auto', [
                                m(Textbox, {
                                    label: "使用者電子信箱",
                                    model: this.model,
                                    field: 'email',
                                    input: {
                                        maxlength: 100,
                                        readonly: !this.isNewUser()
                                    }
                                })
                            ])
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m('button[type="button"].btn.btn-outline-secondary', {
                        onclick: this.close
                    }, '關閉'),
                    m('button[type="button"].btn.btn-primary', {
                        onclick: this.save.bind(this)
                    }, '儲存')
                ])
            ])
        ])
    }
}
export default UserDialog