XMLHttpRequest.prototype.realSend = XMLHttpRequest.prototype.send;
XMLHttpRequest.prototype.send = function(value) {
    this.addEventListener("loadstart", function (e) {
        
        const loading = document.querySelector('.ajax_loader')
        if (loading === null) {
            const backdrop = document.createElement('div')
            backdrop.classList.add('ajax_loader_backdrop')
            const wrapper = document.createElement('div')
            const icon = document.createElement('div')
            wrapper.classList.add('ajax_loader')
            icon.classList.add('ajax_loader-icon')
            wrapper.appendChild(icon)
            document.body.appendChild(wrapper)
            document.body.appendChild(backdrop)
        }
    }, false);
    this.addEventListener('load', () => {
        setTimeout(() => {
            const backdrop = document.querySelector('.ajax_loader_backdrop')
            const loading = document.querySelector('.ajax_loader')
            if (loading === null) {
                return false
            }
            loading.classList.add('exit')
            setTimeout(function() {
                loading.remove()
                backdrop.remove()
            }, 200)
        }, 200)

    }, false);
    this.addEventListener('loadend', (e) => {
        if (e.currentTarget.status === 401) {
            window.location.href = '/Admin/Login'
        }
       
    }, false);
    this.realSend(value);
};
