import m from 'mithril'
import {
    Dialog,
    TextBox,
    TextArea,
} from 'components'
import {
    Basic,
    User
} from 'app/models'


class EditMeta {
    constructor(vnode) {
        this.basic = new Basic()
        Basic.load().then((response) => {
            this.basic = new Basic(response)
        })
    }
    save() {
        // if (this.model.validate()) {
        //     return false
        // }
        this.basic.save().then((response) => {
            Dialog.close(true)
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view() {
        return [m(".modal-dialog[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", "網站基本設定"),
                            m("button.close", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.close()
                                    }
                                },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m("",[
                            m('form[action=""]',
                                [
                                    m('.d-flex.mb-2',[
                                        m('.btn.btn-light.w-10.text-dark.text-left.bg-white.mr-2', '名稱'),
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.basic.title = e.target.value
                                            },
                                            class: 'border-0 form-control',
                                            value: this.basic.title,
                                            type: 'text',
                                        }),
                                        
                                    ]),
                                    m('.mb-2', [
                                        m('.d-flex',[
                                          m('.btn.btn-light.w-10.text-dark.text-left.bg-white.mr-2', '關鍵字'),
                                          m(TextBox, {
                                               oninput: (e) => {
                                                   this.basic.keyword = e.target.value
                                               },
                                               class: 'border-0 form-control',
                                               value: this.basic.keyword,
                                               type: 'text',
                                          }),
                                        ]),
                                        m('.d-flex', [
                                          m('.w-10.mr-1'),
                                          m('small.text-secondary.pl-2','*請用逗號分開各個關鍵字')  
                                        ])
                                        
                                    ]),
                                    m('.mb-2', [
                                    m('.d-flex', [
                                        m('.btn.btn-light.w-10.text-dark.text-left.bg-white.mr-2', '描述'),
                                        m(TextArea, {
                                            oninput: (e) => {
                                                this.basic.meta = e.target.value
                                            },
                                            class: 'border-0 form-control',
                                            value: this.basic.meta,
                                            type: 'text',
                                        }),
                                    ])
                                    ]),
                                    m('.mb-2', [
                                    m('.d-flex', [
                                        m('.btn.btn-light.w-10.text-dark.text-left.bg-white.mr-2', 'Facebook'),
                                        m(TextArea, {
                                            oninput: (e) => {
                                                this.basic.facebook = e.target.value
                                            },
                                            class: 'border-0 form-control',
                                            value: this.basic.facebook,
                                            type: 'text',
                                        }),
                                    ]),
                                ])
                                ]
                            )
                        ])
                    ),
                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.close()
                                        }
                                    },
                                    [
                                        m("i.fa.fa-times-circle"),
                                        "取消"
                                    ]
                                ),
                                m("button.btn.btn-primary[type='button']", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.save()
                                            swal({
                                                title: "設定完成!",
                                                text: "已成功設定資訊，請至列表查看!",
                                                icon: "success",
                                                buttons: false,
                                                timer: 1800,
                                            })
                                        }
                                    },
                                    [
                                        m("i.fa.fa-check-circle"),
                                        "儲存"
                                    ]
                                )
                        ]
                    )
                ]
            )
        ])]
    }
}

export default EditMeta