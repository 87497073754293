import m from 'mithril'

class Footer {
    view({
        attrs
    }) {
        return [
            m("footer.footer",
                [
                    m("span.float-right",
                        [
                            "2019 ",
                            m.trust("&copy;"),
                            ""
                        ]
                    )
                ]
            )
        ]
    }
}

export default Footer