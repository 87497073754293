import m from 'mithril'
import stream from 'mithril/stream'
import MenuComponent from './partials/menu'
import Header from './partials/header'
import Footer from './partials/footer'
import Script from './partials/script'
import classNames from 'classnames'

import {
    Dialog
} from 'components'
import {
    User
} from 'app/models'
import * as Menu from 'app/models/menu'
//let _width = 250
class LayoutComponent {
    constructor() {
        this.navbarToggle = stream(true)
        // this.auth = stream('manager')
    }
    checkRoute() {
        let _route = m.route.get()
        return /^\/site/i.test(_route)
    }
    setWidth(val) {
        this.width = val
    }
    view(vnode) {
        return [
            m('#main',
                m(Header, {
                    navbarToggle: this.navbarToggle,
                    width: this.setWidth.bind(this),
                    // auth: this.auth
                }),
                m('.left.main-sidebar', {
                    style: {
                        //width: `${this.width}px`
                    }
                },
                    m('.sidebar-inner.leftscroll',{
                        style:{
                            overflow:"hidden"
                        }
                    },
                        m(MenuComponent, {
                            navbarToggle: this.navbarToggle,
                            // auth: this.auth,
                            // isSite: /^\/site/i.test(m.route.get())
                        }),
                        m('.clearfix')
                    )
                ),

                m("div.content-page",
                    m("div.content",
                        m("div.container-fluid", [vnode.children])
                    )
                ),
                m(Footer)
            ),            
            m(Dialog),
            m("script", {
                src: `${window.BASE_URL}/lib/jquery/dist/jquery.min.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/lib/scripts/fastclick.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/lib/scripts/admin.js`
            }),
            m("script", {
                src: `https://unpkg.com/sweetalert/dist/sweetalert.min.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/lib/pdfjs/build/pdf.js`
            }),
            m("script", {
                src: `${window.BASE_URL}/lib/pdfjs/build/pdf.worker.js`
            }),

            
        ]
    }
}

export default function (component, opts, auth) {
    return {
        onmatch: (args, requestedPath) => {
            // console.log(requestedPath)
            let roles = []
            roles.push("首頁")
            const found = Menu.ManagementItems.find((item) => {
                // console.log(item,"items")
                // if(requestedPath == "/"){
                //     return true
                // }
                // const match = item.regex.exec(requestedPath)
                // if (match) {
                //     return roles.includes(item.auth)
                // }
                return true
            })
            if (!found) {
                m.route.set("/404")
            }
        },
        render: (vnode) => {
            return m(LayoutComponent, m(component))
        }
    }
}