import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog
} from 'components'
import moment from 'moment'

class DashboardPage {
    constructor() {
        
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder", [
                        m("h1.main-title.float-left.text-menu", m("i.lnr.lnr-home.font-weight-bold.mr-3"), "首頁"),
                        m("ol.breadcrumb.float-right",
                            [
                                m("li.breadcrumb-item.active", "首頁")
                            ]
                        ), m(".clearfix")
                    ])
                )
            ),
            m('', [
                m('.row.no-gutters', [
                    m(".col-md-12",
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-database.mr-2"), "後台管理系統"]),
                                        ]
                                    ),
                                ),
                                m(".card-body", [
                                    m("img[alt='Logo']", {
                                        src: `${window.BASE_URL}/images/admin_index.png`,
                                    }),
                                ])
                            ]
                        )
                    )
                ]),

            ])
        ]
    }
}

export default DashboardPage