import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {

   }

class Basic extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.meta = args.meta || '';
        this.title = args.title || '';
        this.keyword = args.keyword || '';
        this.facebook = args.facebook || '';
        this.address = args.address || '';
        this.created_at = moment(args.created_at).format("YYYY-MM-DD") || "";
        this.updated_at = moment(args.updated_at).format("YYYY-MM-DD") || "";
    }

    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/web`,
        })
    }

    static load() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/web/1`,
        })
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/web`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/web/${id}`,
        })
    }
}

export default Basic