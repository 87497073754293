import m from 'mithril'
import uuid from 'uuid'
import isMobile from 'lib/detect-mobile'
import ConfirmDialog from './confirm'
import Snackbar from './snackbar'
import {
    Dialog    
} from 'components'

let click = false
let offsetTop = 0
let offsetLeft = 0
let clickX = 0
let clickY = 0
let finallyX = 0
let finallyY = 0

class MoveDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model        
        this.conponent = vnode.attrs.conponent        
    }    
    oncreate(vnode) {
        click = false
        offsetTop = 0
        offsetLeft = 0
        clickX = 0
        clickY = 0
        finallyX = 0
        finallyY = 0
        var _modal = vnode.dom.querySelector(".modal-content")
        document.addEventListener("mousemove", function (e) {
            if (click) {
                _modal.style.left = e.clientX - clickX - offsetLeft + finallyX + "px"
                _modal.style.top = e.clientY - clickY - offsetTop + finallyY + "px"
            }
        })
    }
    close(event) {
        click = false
        offsetTop = 0
        offsetLeft = 0
        clickX = 0
        clickY = 0
        finallyX = 0
        finallyY = 0
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return [
            m(`.modal-dialog.modal-${vnode.attrs.size}${(vnode.attrs.isMove) ? "" :".modal-dialog-centered"}[role='document']`, [
                m(".modal-content", {
                    style: {
                        position: (vnode.attrs.isMove) ? "fixed" :"",
                    },

                },
                    [
                        m(".modal-header",
                            {
                                onmousedown: (e) => {
                                    if (vnode.attrs.isMove){
                                        click = true
                                    }                                    
                                    clickX = e.offsetX
                                    clickY = e.offsetY
                                    offsetLeft = e.clientX - e.offsetX
                                    offsetTop = e.clientY - e.offsetY
                                },
                                onmouseup: (e) => {
                                    finallyX = e.clientX - clickX - offsetLeft + finallyX
                                    finallyY = e.clientY - clickY - offsetTop + finallyY
                                    click = false
                                }
                            },
                            [
                    m("h5.modal-title", (this.model.id == 0) ? "紀錄" : "編輯"),
                    m("button.close", {
                        onclick: (e) => {
                            e.preventDefault()
                            this.close()
                        }
                    },
                        m("span", m.trust("&times;"))
                    ),
                ]
            ),
            m(vnode.attrs.conponent,{
                model: vnode.attrs.model
            }),
            
        ]
    )
])]
    }
}
export default MoveDialog