import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    Editor,
} from 'components'
import {
    User,
    ReportUp,
    Authority
} from 'app/models'
import Uploader from 'components/uploader/core'
import {
    PhotoUploader,
} from 'components/uploader'
import {
    MultiPhotosUploader,
} from 'components/uploader'


class IntroComponent {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.languages = [{
            id: 'tw',
            name: '繁體'
        }, {
            id: 'cn',
            name: '簡體'
        }, {
            id: 'en',
            name: '英文'
        }]
        this.select = this.languages[0]
        this.test = true
        this.content = stream(this.model[this.select.id + "_content"])
        this.preview = stream("")
        this.photos = stream([])
        if (this.model.photos != ""){
            this.photos(JSON.parse(this.model.photos))
        }
        if (this.model.banner != "") {
            this.preview(this.model.banner)
        }

    }
    save() {
        this.model[this.select.id + "_content"] = this.content()
        this.model.banner = this.preview()
        this.model.photos = JSON.stringify(this.photos())
        this.model.save().then((response) => {
            swal({
                title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
                text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
                icon: "success",
                buttons: false,
                timer: 1800,
            }).then((response) => {
                Dialog.close(true)
            })
        })
    }
    getPhotoPath(filename) {
        if (filename == undefined || filename == null || filename == '') {
            return `${window.BASE_URL}/images/work/720_535.jpg`
        }
        return `${window.BASE_URL}/images/work/${filename}`
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return [
            m(".modal-body",
                m("",
                    m("form[id='basic-form'][method='post'][novalidate]",
                        m('.row.m-0', [
                            m(".col-12.d-flex.justify-content-between", [
                                m("", [
                                    m("h3.control-label.text-primary.pl-2", `${this.select.name}`),
                                ]),
                                m("", [

                                    this.languages.map((_language) => {
                                        if (_language.id != this.select.id) {
                                            return m("button.btn.btn-success.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button']", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        this.test = false
                                                        this.model[this.select.id + "_content"] = this.content()
                                                        this.select = _language
                                                        this.content(this.model[this.select.id + "_content"])
                                                        m.redraw()
                                                        this.test = true
                                                    }
                                                },
                                                [
                                                    m("i.fa.fa-edit"),
                                                    _language.name
                                                ]
                                            )
                                        }
                                    }),

                                    m("button.btn.btn-primary.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button']", {
                                            onclick: (e) => {
                                                e.preventDefault()
                                                this.save()
                                            }
                                        },
                                        [
                                            m("i.fa.fa-edit"),
                                            "儲存"
                                        ]
                                    ),
                                ]),
                            ]),
                            m(".col-6", [
                                m(PhotoUploader, {
                                    caption: '視覺圖 720*535',
                                    class: 'mb-0',
                                    path: this.getPhotoPath(this.model.banner),
                                    url: `${window.BASE_URL}/api/upload/photo`,
                                    preview: true,
                                    width: 720,
                                    height: 535,
                                    viewW: 280,
                                    viewH: 170,
                                    filename: this.preview,
                                    folder: "work",
                                }),
                            ]),
                            m('.col-md-6',
                                m(".row.no-gutters.w-100", [
                                    m(".col-12", [
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "排序"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.sort = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.sort,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ]),
                                    m(".col-12", [
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "名稱"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model[this.select.id] = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model[this.select.id],
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ]),
                                    m(".col-12", [
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "副標題"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model[this.select.id + "_subject"] = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model[this.select.id + "_subject"],
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ]),
                                    m(".col-6", [
                                        m(".form-radio.form-radio-flat.my-2",
                                            m("label.form-check-label.pl-4",
                                                [
                                                    m("input.form-check-input[type='radio']", {
                                                        checked: this.model.is_top == 1,
                                                        onclick: () => {
                                                            this.model.is_top = 1
                                                        }
                                                    }),
                                                    "顯示",
                                                    m("i.input-helper")
                                                ]
                                            )
                                        ),
                                    ]),
                                    m(".col-6", [
                                        m(".form-radio.form-radio-flat.my-2",
                                            m("label.form-check-label.pl-4",
                                                [
                                                    m("input.form-check-input[type='radio']", {
                                                        checked: this.model.is_top == 0,
                                                        onclick: () => {
                                                            this.model.is_top = 0
                                                        }
                                                    }),
                                                    "不顯示",
                                                    m("i.input-helper")
                                                ]
                                            )
                                        )
                                    ]),
                                ])

                            ),

                            m(".col-12", [
                                m("label.text-dark.mb-1", "內頁圖片"),
                                m(MultiPhotosUploader, {
                                    items: this.photos,
                                    temppath: `${window.BASE_URL}/temp/`,
                                    storepath: `${window.BASE_URL}/images/work/`,
                                    url: `${window.BASE_URL}/api/upload/photo`,
                                    width: 640,
                                    height: 480,
                                    folder: "work"
                                }),
                            ]),
                            m('.col-md-12.col-12.pt-4', [
                                (this.test) ?
                                m(Editor, {
                                    content: this.content
                                }) : ""
                            ])

                        ]),
                    )
                )
            ),

            m(".modal-footer",
                [
                    m("button.btn.btn-label.rounded", {
                            onclick: (e) => {
                                e.preventDefault()
                                this.close()
                            }
                        },
                        [m("i.fa.fa-times-circle"), "關閉"]
                    ),
                    m("button.btn.btn-primary[type='button']", {
                            onclick: (e) => {
                                e.preventDefault()
                                this.save()

                            }
                        },
                        [m("i.fa.fa-check-circle"), "儲存"]
                    )
                ],

            )
        ]


    }
}

export default IntroComponent
