import m from 'mithril'
import EditMeta from './edit_meta'
import EditPicture from './edit_picture'
import EditEmail from './edit_email'
import EditContact from './edit_contact'
import {
    Dialog
} from 'components'
import {
    Basic,
    Mails,
    Banners,
    Method,
    User
} from 'app/models'

class ControlPage {
    constructor() {
        this.basic = new Basic()
        this.showId = null
        Basic.load().then((response)=>{
            this.basic = new Basic(response)
        })
        this.mails = []
        Mails.fetch().then((response) => {
            this.mails = response
        })

        this.banners = []
        Banners.fetch().then((response) => {
            this.banners = response
        })

        this.method = []
        Method.fetch().then((response) => {
            this.method = response
        })
    }

    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder", [
                        m("h1.main-title.float-left.text-menu", m("i.lnr.lnr-laptop-phone.font-weight-bold.mr-3"), "網站設定"),
                        m("ol.breadcrumb.float-right",
                            [
                                m("li.breadcrumb-item", "首頁"),
                                m("li.breadcrumb-item.active", "網站設定")
                            ]
                        ), m(".clearfix")
                    ])
                )
            ),
            m(".row",
                [
                    m('.col-4',[
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-edge.mr-2"), "網站基本設定"]),
                                            m("button.btn.btn-custom.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(EditMeta, {
                                                        attrs: {
                                                            model: new Basic(),
                                                        },
                                                        didHide: (returnValue) => {
                                                            if (returnValue) {
                                                                Basic.fetch().then((response) => {
                                                                    this.basic = new Basic(response)
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                            }, [m("i.fa.fa-cogs.mr-1"), "設定"])
                                        ]
                                    ),
                                ),
                                m(".card-body", [
                                    // this.service.map((item, index) => {
                                    //     return [
                                            m('.d-flex', [
                                                m('.d-flex.align-items-top.px-3.py-2.border.text-muted', {
                                                    style:{
                                                        width: '120px'
                                                    }
                                                },
                                                    '網站名稱:'
                                                ),
                                                m('.col.px-3.py-2.border.text-dark', this.basic.title)
                                            ]),
                                            m('.d-flex', [
                                                m('.d-flex.align-items-top.px-3.py-2.border.text-muted', {
                                                    style:{
                                                        width: '120px'
                                                    }
                                                },
                                                    '網站關鍵字:'
                                                ),
                                                m('.col.px-3.py-2.border.text-dark', this.basic.keyword)
                                            ]),
                                            m('.d-flex', [
                                                m('.d-flex.align-items-top.px-3.py-2.border.text-muted', {
                                                    style:{
                                                        width: '120px'
                                                    }
                                                },
                                                    '網站描述:'
                                                ),
                                                m('.col.px-3.py-2.border.text-dark', this.basic.meta)
                                            ]),
                                            m('.d-flex', [
                                                m('.d-flex.align-items-top.px-3.py-2.border.text-muted', {
                                                    style:{
                                                        width: '120px'
                                                    }
                                                },
                                                    'Facebook:'
                                                ),
                                                m('.col.px-3.py-2.border.text-dark', this.basic.facebook)
                                            ])
                                    //     ]
                                    // }),
                                ])
                            ]
                        ),
                        m(".card.mb-0",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-server.mr-2"), "諮詢聯絡事項設置"]),
                                            m("button.btn.btn-custom.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(EditContact, {
                                                        attrs: {
                                                            model: new Method(),
                                                        },
                                                        didHide: (returnValue) => {
                                                            if (returnValue) {
                                                                Method.fetch().then((response) => {
                                                                    this.method = response
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                            }, [m("i.fa.fa-cogs.mr-1"), "設定"])
                                        ]
                                    ),
                                ),
                                m(".card-body", [
                                    m('div.tree',
                                        // m('p.mb-1.border', '借款方式'),
                                        m('ul',
                                            [
                                                this.method.map((item, index) => {
                                                    return [
                                                        m('li',
                                                            [
                                                                m('i.fa.fa-paste'),
                                                                m('a.first-title', item.name),
                                                            ]
                                                        ),
                                                    ]
                                                }),
                                            ]
                                        )
                                    ),
                                ])
                            ]
                        ),
                    ]),
                    m('.col-4', [
                        m(".card.mb-0",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-envelope-open-o.mr-2"), "寄送聯絡表單之E-mail設定"]),
                                            m("button.btn.btn-custom.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(EditEmail, {
                                                        attrs: {
                                                            model: new Mails(),
                                                        },
                                                        didHide: (returnValue) => {
                                                            
                                                            if (returnValue) {
                                                                Mails.fetch().then((response) => {
                                                                    this.mails = response
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                            }, [m("i.fa.fa-cogs.mr-1"), "設定"])
                                        ]
                                    ),
                                ),
                                m(".card-body", [
                                    this.mails.map((item, index) => {
                                        return [
                                        m('.col-auto.px-0',
                                            [
                                                m('label.sr-only','email'),
                                                m('.input-group.mb-2',
                                                    [
                                                        m('.input-group-prepend',
                                                            m('.input-group-text', index+1)
                                                        ),
                                                        m(`input.form-control.form-control-lg.text-black-50[type="email"][disabled]`,{
                                                            value: item.mail
                                                        })
                                                    ]
                                                )
                                            ]
                                        )
                                        ]
                                    }),
                                ])
                            ]
                        ),
                    ]),
                    // m('.col-4', [
                    //     m(".card.mb-0",
                    //         [
                    //             m(".card-header",
                    //                 m('.d-flex.justify-content-between.align-items-center',
                    //                     [
                    //                         m("h3", [m("i.fa.fa-picture-o.mr-2"), "首頁Banner設置"]),
                    //                         m("button.btn.btn-custom.btn-sm[type='button']", {
                    //                             onclick: (e) => {
                    //                                 e.preventDefault()
                    //                                 Dialog.show(EditPicture, {
                    //                                     // attrs: {
                    //                                     //     model: new Service(),
                    //                                     // },
                    //                                     didHide: (returnValue) => {
                    //                                         if (returnValue) {
                    //                                             Banners.fetch().then((response) => {
                    //                                                 this.banners = response
                    //                                             })
                    //                                         }
                    //                                     }
                    //                                 })
                    //                             }
                    //                         }, [m("i.fa.fa-cogs.mr-1"), "設定"])
                    //                     ]
                    //                 ),
                    //             ),
                    //             m(".card-body", [
                    //                 this.banners.map((item, index) => {
                    //                     return [
                    //                         m('',
                    //                             m("img.img-fluid[alt='代表ICON']", {
                    //                                 src: `${window.BASE_URL}/images/banners/${item.filename}`,
                    //                                 // style: {
                    //                                 //     "max-width": "60px",
                    //                                 //     "height": "auto"
                    //                                 // }
                    //                             }),
                    //                             m('hr.w-100.my-2')
                    //                         ),
                    //                     ]
                    //                 }),
                    //             ])
                    //         ]
                    //     ),
                    // ])
                ]
            )
        ]
    }
}

export default ControlPage