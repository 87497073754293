import m from 'mithril'
import stream from 'mithril/stream'
import uuid from 'uuid'
import {
    Dialog,
    TextBox,
    TextField,
    TextArea
} from 'components'
import {
    User,
    Authority
} from "app/models";


class UserPermissionDialog {
    constructor(vnode) {
        this.menus = [
            "dashboard",
            "關於Aid",
            "團隊陣容",
            "歷史業績",
            "代表作品",
            "最新消息",
            "聯絡我們",
            "網站設定",
            "系統帳號管理"]
        this.model = new Authority({
            userId: vnode.attrs.id,
            // type: MANAGER,
            authorities: []
        })
        this.model.fetch(this.model.userId).then((response)=>{
            // if (!this.model.checkAuth("最高管理權限") && !this.model.checkAuth("一般管理員")){
            //     this.model.toggleAuth("一般管理員")
            // }
        })
    }

    save(event, value) {
        this.model.save().then(() => {
            this._close()
        })
    }

    close(event) {
        this._close()
    }

    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }

    view({
        attrs
    }) {
        return [
            m('.modal-dialog', [
                m('.modal-content', [
                    m('.modal-header.pb-0.text-white', {
                        style: {
                            background: '#424242'
                        }
                    }, [
                            m('.modal-title', [
                                m('.h5', [
                                    '權限修改'
                                ])
                            ]),
                            m("button.close.text-white", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span[aria-hidden='true']",
                                    m.trust("&times;")
                                )
                            )
                        ]),
                    m('.modal-body', [
                        m('.container-fluid', [
                            m('.col-12', [
                                m('h5', [
                                    m('span', this.name)
                                ])
                            ]),
                            m('.row.no-gutters', [
                                // m('.col-6.pb-3', [
                                //     m('label', [
                                //         m('input[type="checkbox"]', {
                                //             checked: this.model.checkAuth("一般管理員"),
                                //             onclick: (e) => {
                                //                 this.model.toggleAuth("一般管理員")
                                //                 if (this.model.checkAuth("最高管理權限")){
                                //                     this.model.toggleAuth("最高管理權限")
                                //                 }
                                //             }
                                //         }),
                                //         m('span',{
                                //             style:{
                                //                 fontSize:"1.5rem"
                                //             }
                                //         }, "一般管理員")
                                //     ])
                                // ]),
                                // m('.col-6', [
                                //     m('label', [
                                //         m('input[type="checkbox"]', {
                                //             checked: this.model.checkAuth("最高管理權限"),
                                //             onclick: (e) => {
                                //                 this.model.toggleAuth("最高管理權限")
                                //                 if (this.model.checkAuth("一般管理員")) {
                                //                     this.model.toggleAuth("一般管理員")
                                //                 }
                                //             }
                                //         }),
                                //         m('span', {
                                //             style: {
                                //                 fontSize: "1.5rem"
                                //             }
                                //         }, "最高管理權限")
                                //     ])
                                // ]),
                                this.menus.map((menu) => {
                                    return [
                                        m('.col-12', [
                                            m('label', [
                                                m('input[type="checkbox"]', {
                                                    checked: this.model.checkAuth(menu),
                                                    onclick: (e) => {
                                                        this.model.toggleAuth(menu)
                                                    }
                                                }),
                                                m('span', menu)
                                            ])
                                        ])
                                    ]
                                })
                            ])
                        ]),
                    ]),
                    m('.modal-footer', [
                        m("button.btn.btn-success[type='button']", {
                            onclick: (e) => {
                                e.preventDefault()
                                this.save()
                            }
                        },
                            "儲存"
                        ),
                        m("button.btn.btn-outline-secondary[type='button']", {
                            onclick: (e) => {
                                e.preventDefault()
                                this.close()
                            }
                        },
                            "取消"
                        )
                    ])
                ])
            ])
        ]
    }
}

export default UserPermissionDialog