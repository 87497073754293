import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {

}

class Work extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.intros = args.intros || [];
        this.tw = args.tw || '';      //繁體簡介 
        this.cn = args.cn || '';      //英文簡介 
        this.en = args.en || '';      //簡體簡介
        
        this.sort = args.sort || '1';         
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }
    
    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/work`,
        })
    }    

    static load(id) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/work/${id}`,
        })
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/work`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/work/${id}`,
        })
    }

    
}

export default Work