import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
} from 'components'
import {
    User,
    Banners
} from 'app/models'
import uuid from 'uuid'
import Uploader from 'components/uploader/uploader'

const ADD = 'add',
    UPDATE = 'update'

let saveTime;

const isOverlap = (dragObjectRect, dragOverObjectRect, shiftX, shiftY) => {

    let dragLeft = dragObjectRect.left + shiftX,
        dragTop = dragObjectRect.top + shiftY,
        dragRight = dragObjectRect.right + shiftX,
        dragBottom = dragObjectRect.bottom + shiftY

    const left = Math.max(dragLeft, dragOverObjectRect.left)
    const right = Math.min(dragRight, dragOverObjectRect.right)
    const top = Math.max(dragTop, dragOverObjectRect.top)
    const bottom = Math.min(dragBottom, dragOverObjectRect.bottom)
    if (left < right && top < bottom) {

        const width = right - left
        const height = bottom - top
        const intersec = width * height
        if (intersec > (dragOverObjectRect.width * dragOverObjectRect.height) / 2) {
            return true
        }
    }

    return false
}

const findeIndex = (array, id) => {
    const index = array.findIndex((item) => {
        return item.uuid == id
    })

    return index
}


class EditPicture {
    constructor(vnode) {

        this.model = []
        Banners.fetch().then((response)=>{
            this.model = response
        })
        this.action = ADD
        this.dragObject = null
        this.dragObjectRect = null
        this.cloneNode = null
        this.shift = null


    }
    save() {
        Banners.save(this.model).then((response) => {
            Dialog.close(true)
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view() {
        return [m(".modal-dialog.modal-lg[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", "首頁BANNER設置"),
                            m("button.close", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.close()
                                    }
                                },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m("h5.text-danger", "*圖片尺寸最小為1440*400"),
                        m('.banner_column', [
                            m('.d-flex.flex-wrap', [
                                this.model.map((item) => {
                                    const hover = false
                                    return [m('row',
                                        m('.ca-thumbnail[draggable="true"]', {
                                            key: item.uuid,
                                            "data-id": item.uuid,
                                            class: (item.dragging) ? 'dragging' : '',
                                            style: {
                                                "width": "748px",
                                                "height": "244px",
                                                "margin": "10px 0 0 8px",
                                                "background-image": (item.filename != 'temp' && !item.dragging) ? `url(/images/banners/${item.filename}` : '',
                                                "background-size": "cover",
                                            },

                                            onmouseover: (e) => {
                                                const tools = e.currentTarget.querySelector('.ca-trash-tool')
                                                tools.style.opacity = 1;
                                            },
                                            onmouseout: (e) => {
                                                const tools = e.currentTarget.querySelector('.ca-trash-tool')
                                                tools.style.opacity = 0;
                                            },
                                            ondragstart: (e) => {
                                                //控制工具列不顯示 
                                                const tools = e.currentTarget.querySelector('.ca-trash-tool')
                                                tools.style.opacity = 0;

                                                this.dragObject = e.currentTarget

                                                e.dataTransfer.effectAllowed = 'move'
                                                this.shift = {
                                                    x: e.pageX,
                                                    y: e.pageY
                                                }

                                                this.dragObjectRect = this.dragObject.getBoundingClientRect()
                                                this.cloneNode = e.currentTarget.cloneNode(true)
                                                this.cloneNode.style.width = this.dragObjectRect.width + 'px'
                                                this.cloneNode.style.height = this.dragObjectRect.height + 'px'
                                                this.cloneNode.style.position = 'fixed'
                                                this.cloneNode.style.bottom = '-10000px'
                                                this.cloneNode.style.backgroundSize = 'cover'
                                                this.cloneNode.style.backgroundPosition = 'center'
                                                document.body.appendChild(this.cloneNode)
                                                e.dataTransfer.setDragImage(this.cloneNode, e.pageX - this.dragObjectRect.left, e.pageY - this.dragObjectRect.top)
                                                item.dragging = true
                                            },
                                            ondragover: (e) => {
                                                e.preventDefault()

                                                if (e.currentTarget === this.dragObject) {
                                                    return false
                                                }
                                                const mouseShift = {
                                                    x: e.pageX - this.shift.x,
                                                    y: e.pageY - this.shift.y
                                                }
                                                const overRect = e.currentTarget.getBoundingClientRect()
                                                //是否overlap超過drag over object的一半面積
                                                const overlap = isOverlap(this.dragObjectRect, overRect, mouseShift.x, mouseShift.y)
                                                if (overlap) {

                                                    const to = findeIndex(this.model, e.currentTarget.dataset.id)
                                                    const from = findeIndex(this.model, this.dragObject.dataset.id)
                                                    this.model.splice(to, 0, this.model.splice(from, 1)[0])
                                                }
                                            },
                                            ondragend: (e) => {
                                                item.dragging = false
                                                document.body.removeChild(this.cloneNode)
                                                if (this.model.length > 0) {
                                                    this.model.map((banner, index) => {
                                                        banner.sort = index
                                                    })

                                                    // clearTimeout(saveTime)
                                                    // saveTime = setTimeout(() => {
                                                    //     Banner.sort(this.model)
                                                    // }, 2000);
                                                }

                                            }
                                        }, [
                                                (item.loading) ? m('.loading_icon') : '',
                                                m('.ca-trash-tool.h-100.d-flex.justify-content-end.align-items-end.p-2', [
                                                    m('a.btn.btn-outline-light[href="#"]', {
                                                        onclick: (e) => {
                                                            e.preventDefault()

                                                            if (confirm("是否要刪除本圖片?")) {
                                                                const banner = this.model.findIndex((banner) => {
                                                                    return banner.uuid === item.uuid
                                                                })
                                                                this.model.splice(banner, 1)
                                                                // Banner.destory('CAROUSEL', item.uuid).then(() => {
                                                                //     this.model.splice(banner, 1)
                                                                // })
                                                            }

                                                        }
                                                    },
                                                        m('i.fa.fa-trash')
                                                    )
                                                ])
                                            ]),

                                        m('.w-100.pt-1.pl-2.mb-3', [
                                            m("input.form-control[placeholder='請設定連結'][type='text']", {
                                                oninput: (e) => {
                                                    item.website = e.target.value
                                                },
                                                value: item.website
                                            })
                                        ]),
                                    )]
                                }
                                ),
                                m('button.upload-indicator', {
                                    style: {
                                        "width": "768px",
                                        "height": "264px"
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.action = ADD
                                        document.querySelector('#uploader_browser').click()
                                    }
                                }, [
                                        m('i.fa.fa-upload.fa-3x')
                                    ]),
                                m('input#uploader_browser.file_browser[type="file"][name="files[]"][accept="image/*"]', {
                                    onchange: (e) => {
                                        if (Uploader.uploading) {
                                            return false
                                        }
                                        if (e.target.files.length === 0) {
                                            return false
                                        }
                                        if (!Uploader.filterExtension(e.target.files)) {
                                            alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                                            return false
                                        }
                                        let pid = uuid.v1().toUpperCase()
                                        if (this.action === ADD) {
                                            this.model.push({
                                                filename: 'temp',
                                                sort: this.model.length,
                                                website: this.model.website,
                                                uuid: pid,
                                                loading: true,
                                                id:0
                                            })
                                        }

                                        if (this.action === UPDATE) {
                                            ui.item.loading = true
                                        }

                                        Uploader.upload(e.target.files[0], {
                                            width: 1440,
                                            height: 480,
                                            url: `${window.BASE_URL}/api/upload/photo`,
                                            folder: "banners",
                                        }).then((response) => {
                                            const found = this.model.find((banner) => {
                                                return banner.uuid === pid
                                            })
                                            found.filename = response.filename,
                                                found.website = response.website,
                                                found.loading = false,
                                                e.target.value = "";
                                            return found
                                        })
                                    }
                                })
                            ])
                        ])
                    ),
                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.close()
                                    }
                                },
                                [
                                    m("i.fa.fa-times-circle"),
                                    "取消"
                                ]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.save()
                                        swal({
                                            title: "設定完成!",
                                            text: "已成功設定資訊，請至列表查看!",
                                            icon: "success",
                                            buttons: false,
                                            timer: 1800,
                                        })
                                    }
                                },
                                [
                                    m("i.fa.fa-check-circle"),
                                    "儲存"
                                ]
                            )
                        ]
                    )
                ]
            )
        ])]
    }
}

export default EditPicture