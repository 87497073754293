import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {

}

class WorkIntro extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.work_id = args.work_id || 0;
        this.is_top = args.is_top || false;
        this.photos = args.photos || "";
        this.banner = args.banner || "";
        this.tw = args.tw || '';      //繁體簡介 
        this.cn = args.cn || '';      //英文簡介 
        this.en = args.en || '';      //簡體簡介      
        this.tw_content = args.tw_content || '';      //繁體簡介 
        this.cn_content = args.cn_content || '';      //英文簡介 
        this.en_content = args.en_content || '';      //簡體簡介    
        this.tw_subject = args.tw_subject || '';      //繁體簡介 
        this.cn_subject = args.cn_subject || '';      //英文簡介 
        this.en_subject = args.en_subject || '';      //簡體簡介 
        this.sort = args.sort || '';      //簡體簡介      
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }    
   

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/work_intro`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/work_intro/${id}`,
        })
    }

    
}

export default WorkIntro