export { default as DashboardPage } from './management/dashboard'
export { default as BaseInfoPage } from './management/baseinfo'
export { default as GroupPage } from './management/group'
export { default as HistoryPage } from './management/history'
export { default as ControlPage } from './management/control'
export { default as WorkPage } from './management/work'
export { default as AccountPage } from './management/account'
export { default as NewsPage } from './management/news'
export { default as ContactPage } from './management/contact'
export { default as ErrorPage } from './error'

