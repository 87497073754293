import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
} from 'components'
import {
    Region,
    City,
    Method,
    User
} from 'app/models'


class EditContact {
    constructor(vnode) {
        // this.region = []
        // Region.fetch().then((response) => {
        //     this.region = response
        // })
        // this.city = []
        // City.fetch().then((response) => {
        //     this.city = response
        // })

        this.method = []
        Method.fetch().then((response) => {
            this.method = response
        })
    }
    save() {
        Method.save(this.method).then((response) => {
            Dialog.close(true)
        })
    //     if (this.model.validate()) {
    //         return false
    //     }
    //     this.model.save().then((response) => {
    //         Dialog.close(true)
    //     })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view() {
        return [m(".modal-dialog[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", "諮詢聯絡事項設置"),
                            m("button.close", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.close()
                                    }
                                },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m("",
                            m('.row.mx-0',[
                                // m('.col-3.border-right',[
                                //     m('.h5','縣市'),
                                //     this.region.map((item, index) => {
                                //         return [
                                //             m(".form-group.d-flex", [
                                //                 m(TextBox, {
                                //                     oninput: (e) => {
                                //                         item.name = e.target.value
                                //                     },
                                //                     class: 'border-0 form-control mr-2 w-180',
                                //                     value: item.name,
                                //                     type: 'text',
                                //                 }),
                                //                 m("button.btn.btn-light[type='button']", {
                                //                         onclick: (e) => {
                                //                             e.preventDefault()
                                //                             swal({
                                //                                     title: "是否確認刪除",
                                //                                     text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                //                                     icon: "warning",
                                //                                     dangerMode: true,
                                //                                     buttons: ["取消", "確認"]
                                //                                 })
                                //                                 .then((willDelete) => {
                                //                                     if (willDelete) {
                                //                                         swal("您的資料已成功刪除", {
                                //                                             icon: "success",
                                //                                             buttons: {
                                //                                                 cancel: false,
                                //                                                 confirm: "確認",
                                //                                             },
                                //                                         });
                                //                                         Region.delete(item.id).then(() => {
                                //                                             Region.fetch().then((response) => {
                                //                                                 this.region = response
                                //                                             })
                                //                                         })
                                //                                     } else {
                                //                                         swal("您的資料未被刪除");
                                //                                     }
                                //                                 });
                                //                         }
                                //                     },
                                //                     [m("i.fa.fa-trash-o"), "刪除"]
                                //                 )
                                //             ])
                                //         ]
                                //     }),
                                //     m('.row', [
                                //         m('.col-12.my-2', m('button.btn.btn-outline-custom.btn-block', '新增縣市'))
                                //     ])
                                // ]),
                                // m('.col-5.border-right', [
                                //     m('.h5', '地區', m('.small.d-inline-block.mx-1','*請選擇對應城市')),
                                //     this.city.map((item, index) => {
                                //         return [
                                //             m(".form-group.d-flex", [
                                //                 m(TextBox, {
                                //                     oninput: (e) => {
                                //                         item.name = e.target.value
                                //                     },
                                //                     class: 'border-0 form-control mr-2 w-200',
                                //                     value: item.name,
                                //                     type: 'text',
                                //                 }),
                                //                 m('.mr-2', [
                                //                     m("select.form-control.border-0", {
                                //                         onchange: (e) => {
                                //                             item.region_id = e.target.value
                                //                         }
                                //                     }, [
                                //                         m("option", '--請選擇縣市--'),
                                //                         // this.categories.map((category) => {
                                //                         //     if (User.unit_judge(category.id)) {
                                //                         //         return m('option', {
                                //                         //             value: category.id,
                                //                         //             selected: item.region_id == category.id
                                //                         //         }, category.name)
                                //                         //     }
                                //                         // }),
                                //                         // m('option', {
                                //                         //     value: region_id,
                                //                         //     selected: item.region_id == region_id
                                //                         // }, region_id)
                                //                     ])
                                //                 ]),
                                //                 m("button.btn.btn-light[type='button']", {
                                //                         onclick: (e) => {
                                //                             e.preventDefault()
                                //                             swal({
                                //                                     title: "是否確認刪除",
                                //                                     text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                //                                     icon: "warning",
                                //                                     dangerMode: true,
                                //                                     buttons: ["取消", "確認"]
                                //                                 })
                                //                                 .then((willDelete) => {
                                //                                     if (willDelete) {
                                //                                         swal("您的資料已成功刪除", {
                                //                                             icon: "success",
                                //                                             buttons: {
                                //                                                 cancel: false,
                                //                                                 confirm: "確認",
                                //                                             },
                                //                                         });
                                //                                         City.delete(item.id).then(() => {
                                //                                             City.fetch().then((response) => {
                                //                                                 this.city = response
                                //                                             })
                                //                                         })
                                //                                     } else {
                                //                                         swal("您的資料未被刪除");
                                //                                     }
                                //                                 });
                                //                         }
                                //                     },
                                //                     [m("i.fa.fa-trash-o"), "刪除"]
                                //                 )
                                //             ])
                                //         ]
                                //     }),
                                //     m('.row', [
                                //         m('.col-12.my-2', m('button.btn.btn-outline-custom.btn-block', '新增地區'))
                                //     ])
                                // ]),
                                m('.col-12', [
                                    // m('.h5', '借款方式'),
                                    this.method.map((item, index) => {
                                        return [
                                            m(".form-group.d-flex", [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        item.name = e.target.value
                                                    },
                                                    class: 'border-0 form-control mr-2',
                                                    value: item.name,
                                                    type: 'text',
                                                }),
                                                m("button.btn.btn-light[type='button']", {
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            swal({
                                                                    title: "是否確認刪除",
                                                                    text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: ["取消", "確認"]
                                                                })
                                                                .then((willDelete) => {
                                                                    if (willDelete) {
                                                                        swal("您的資料已成功刪除", {
                                                                            icon: "success",
                                                                            buttons: {
                                                                                cancel: false,
                                                                                confirm: "確認",
                                                                            },
                                                                        });
                                                                        // Method.delete(item.id).then(() => {
                                                                        //     Method.fetch().then((response) => {
                                                                        //         this.method = response
                                                                        //     })
                                                                        // })
                                                                        this.method.splice(index, 1)



                                                                    } else {
                                                                        swal("您的資料未被刪除");
                                                                    }
                                                                });
                                                        }
                                                    },
                                                    [m("i.fa.fa-trash-o"), "刪除"]
                                                )
                                            ])
                                        ]
                                    }),
                                    m('.row', [
                                        m('.col-12.my-2',{
                                            onclick:(e)=>{
                                                this.method.push(new Method)
                                            }
                                        }, m('button.btn.btn-outline-custom.btn-block', '新增諮詢聯絡事項'))
                                    ])
                                ]),
                            ]),
                        )
                    ),
                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.close()
                                    }
                                },
                                [
                                    m("i.fa.fa-times-circle"),
                                    "取消"
                                ]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.save()
                                        swal({
                                            title: "設定完成!",
                                            text: "已成功設定資訊，請至列表查看!",
                                            icon: "success",
                                            buttons: false,
                                            timer: 1800,
                                        })
                                    }
                                },
                                [
                                    m("i.fa.fa-check-circle"),
                                    "儲存"
                                ]
                            )
                        ]
                    )
                ]
            )
        ])]
    }
}

export default EditContact