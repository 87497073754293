import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {

   }

class Group extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.tw = args.tw || '';      //繁體簡介 
        this.cn = args.cn || '';      //英文簡介 
        this.en = args.en || '';      //簡體簡介        
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static load(id) {
        Group.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/group/${id}`,
        }).then((response) => {
            Group.loading = false
            return response
        })
    }

    save() {
        Group.loading = true
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/group`,
            data: this
        }).then((response) => {
            Group.loading = false
            return response
        })
    }
}

export default Group