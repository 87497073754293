import m from 'mithril'
import loadGoogleMapsApi from 'load-google-maps-api-2'

const googleMapKey = loadGoogleMapsApi({
    key: 'AIzaSyD8-jAg-6iUJ4moS5cPEmI3zeK6JB6yD0w'
})

let marker = null,
    map,
    infowindow


function createMarker(latlng, name, html, infowindow) {
    const contentString = html;
    const marker = new google.maps.Marker({
        position: latlng,
        map: map,
        zIndex: Math.round(latlng.lat() * -100000) << 5
    });

    google.maps.event.addListener(marker, 'click', () => {
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
    });
    google.maps.event.trigger(marker, 'click');
    return marker;
}


class Map {
    constructor(vnode) {
        this.lat = vnode.attrs.lat
        this.lng = vnode.attrs.lng
        this.updateLocation = vnode.attrs.updateLocation
        this.isDisabled = vnode.attrs.isDisabled
        console.log(this.lat(), this.lng(), vnode.attrs.lat(), vnode.attrs.lng())
    }
    oncreate(vnode) {
        const self = this
        googleMapKey.then((googleMaps) => {
            map = new googleMaps.Map(vnode.dom.querySelector('#map'), {
                center: {
                    lat: 25.0436094,
                    lng: 121.5574128
                },
                zoom: 13
            })

            infowindow = new google.maps.InfoWindow({
                size: new google.maps.Size(150, 50)
            });

            if (self.lat() && self.lng()) {
                if (marker) {
                    marker.setMap(null);
                    marker = null;
                }
                const _lat = parseFloat(self.lat())
                const _lng = parseFloat(self.lng())
                const latlng = new google.maps.LatLng(_lat, _lng)
                marker = createMarker(latlng, "name", "<b>位置</b><br>" + latlng, infowindow);
                map.panTo({
                    lat: _lat,
                    lng: _lng
                });
            }

            google.maps.event.addListener(map, 'click', () => {
                infowindow.close();
            });

            google.maps.event.addListener(map, 'click', (event) => {
                if (!this.isDisabled()) {
                    return false
                }
                if (marker) {
                    marker.setMap(null);
                    marker = null;
                }
                self.lat(event.latLng.lat())
                self.lng(event.latLng.lng())
                marker = createMarker(event.latLng, "name", "<b>位置</b><br>" + event.latLng, infowindow);
            });

        })
    }
    onupdate() {
        // console.log(this.updateLocation())
        // if (this.updateLocation()) {
            const _lat = parseFloat(this.lat())
            const _lng = parseFloat(this.lng())
            const latlng = new google.maps.LatLng(_lat, _lng)
            if (marker) {
                marker.setMap(null);
                marker = null;
            }
            marker = createMarker(latlng, "name", "<b>位置</b><br>" + latlng, infowindow)
            map.panTo({
                lat: _lat,
                lng: _lng
            });
            this.updateLocation(false)
        // }
    }
    view() {
        return m('.w-100.h-100', [
            m('#map.h-100')
        ])
    }
}

export default Map