import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {

}

class History extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.year = args.year || 2020;
        this.intro = args.intro || [];
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }
    
    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/history`,
        })
    }    

    static load(id) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/history/${id}`,
        })
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/history`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/history/${id}`,
        })
    }
}

export default History