import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'


const constraints = {
    sort: {
        presence: {
            message: "^請輸入排序"
        }
    }



}

class HistoryIntro extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.history_id = args.history_id || null;
        this.tw = args.tw || '';      //繁體簡介 
        this.cn = args.cn || '';      //英文簡介 
        this.en = args.en || '';      //簡體簡介      
        this.sort = args.sort || null;      //簡體簡介      
    }    

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/history_intro`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/history_intro/${id}`,
        })
    }

}

export default HistoryIntro